import { async } from '@angular/core/testing';
import {NgModule} from '@angular/core';
import {ApolloModule, APOLLO_OPTIONS} from 'apollo-angular';
import { ApolloClientOptions, ApolloLink, InMemoryCache, split } from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { setContext } from '@apollo/client/link/context';
import { Storage } from '@capacitor/storage';

import {WebSocketLink} from '@apollo/client/link/ws';
import {getMainDefinition} from '@apollo/client/utilities';

const uri = 'https://graphql.mo.vc/v1/graphql'; // <-- add the URL of the GraphQL server here live
// const uri = 'https://devgraphql.mo.vc/v1/graphql'; // <-- add the URL of the GraphQL server here dev


export function createApollo(httpLink: HttpLink) {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8'
    }
  }));



  const auth = setContext(async(_, { headers }) => {
    // Grab token if there is one in storage or hasn't expired
    const token = await Storage.get({ key: 'accessToken' });

if(token.value) {

return {
  headers: {
    Authorization: `Bearer ${token.value}`,
  },
};
  }
  else{
    return {};
  }
  });


  const ws = new WebSocketLink({
    uri: `wss://graphql.mo.vc/v1/graphql`,
    options: {
      reconnect: true,
      lazy: true,
      connectionParams: async () => {
        const token = await Storage.get({ key: 'accessToken' });
        return {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        };
      }
    },
  });

  const http = ApolloLink.from([basic , auth, httpLink.create({ uri })]);

  const link = split(
    // split based on operation type
    ({query}) => {
      const data = getMainDefinition(query);
      return (
        data.kind === 'OperationDefinition' && data.operation === 'subscription'
      );
    },
    ws,
    http,
  );

  const cache = new InMemoryCache();

  return {
    link,
    cache
  }
}

@NgModule({
  exports: [
    HttpClientModule,
    ApolloModule,
  ],
  providers: [{
    provide: APOLLO_OPTIONS,
    useFactory: createApollo,
    deps: [HttpLink]
  }]
})
export class GraphQLModule {}

