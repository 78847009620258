import { LoginPage } from './login/login.page';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'folder/Inbox',
  //   pathMatch: 'full'
  // },

  // {
  //   path: '',
  //   loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  // },
  // {
  //   path: 'folder/:id',
  //   loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule),
  //   // canActivate: [AuthGuardService],
  // },
  {
    path: 'login',
    component: LoginPage,
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'google-maps',
    loadChildren: () => import('./google-maps/google-maps.module').then( m => m.GoogleMapsPageModule)
  },

  {
    path: 'registration',
    loadChildren: () => import('./registration/registration.module').then( m => m.RegistrationPageModule)
  },

  {
    path: 'registration/:mobile',
    loadChildren: () => import('./registration/registration.module').then( m => m.RegistrationPageModule)
  },

  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
   {
    path: 'email-login',
    loadChildren: () => import('./email-login/email-login.module').then( m => m.EmailLoginPageModule)
  },

    {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'otp-verification/:mobile',
    loadChildren: () => import('./otp-verification/otp-verification.module').then( m => m.OtpVerificationPageModule)
  },
  {
    path: 'termsandcondition',
    loadChildren: () => import('./termsandcondition/termsandcondition.module').then( m => m.TermsandconditionPageModule)
  },

  {
    path: '',
    loadChildren: () => import('./tab-bar/tab-bar.module').then( m => m.TabBarPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then( m => m.ChangePasswordPageModule),
    canActivate: [AuthGuardService]
  },

  {
    path: 'reviews/:id',
    loadChildren: () => import('./reviews/reviews.module').then( m => m.ReviewsPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then( m => m.NotificationsPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'shareandinvite',
    loadChildren: () => import('./shareandinvite/shareandinvite.module').then( m => m.ShareandinvitePageModule),
    canActivate: [AuthGuardService],
  },

  {
    path: 'about-us',
    loadChildren: () => import('./about-us/about-us.module').then( m => m.AboutUsPageModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./support/support.module').then( m => m.SupportPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'doctor-chat/:id',
    loadChildren: () => import('./doctor-chat/doctor-chat.module').then( m => m.DoctorChatPageModule),
    canActivate: [AuthGuardService],
  },

  {
    path: 'wishlist',
    loadChildren: () => import('./wishlist/wishlist.module').then( m => m.WishlistPageModule),
    canActivate: [AuthGuardService],
  },
    {
    path: 'account-history',
    loadChildren: () => import('./account-history/account-history.module').then( m => m.AccountHistoryPageModule),
    canActivate: [AuthGuardService],
  },


  //dashboad
  {
    path: 'dashboard/appointment',
    loadChildren: () =>
    import('./appointment/appointment.module').then(m => m.AppointmentPageModule),
  },
  {
    path: 'dashboard/specialist/appointment/:id',
    loadChildren: () =>
    import('./appointment/appointment.module').then(m => m.AppointmentPageModule),
  },
  {
    path: 'dashboard/symptoms/appointment/:id/:symptom',
    loadChildren: () =>
    import('./appointment/appointment.module').then(m => m.AppointmentPageModule),
  },

  {
    path: 'dashboard/symptoms',
    loadChildren: () => import('./symptoms/symptoms.module').then( m => m.SymptomsPageModule)
  },
  {
    path: 'dashboard/specialist',
   loadChildren: () => import('./specialist/specialist.module').then( m => m.SpecialistPageModule)
  },
  {
    path: 'dashboard/appointment/view-doctor/:id',
    loadChildren: () => import('./view-doctor/view-doctor.module').then( m => m.ViewDoctorPageModule)
  },
  {
    path: 'dashboard/appointment/appointment-booking/:id',
    loadChildren: () =>import('./appointment-booking/appointment-booking.module').then( m => m.AppointmentBookingPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'dashboard/appointment/confirm-booking/:id',
    loadChildren: () => import('./confirm-booking/confirm-booking.module').then( m => m.ConfirmBookingPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'dashboard/blogs',
    loadChildren: () => import('./blogs/blogs.module').then( m => m.BlogsPageModule)
  },
  {
    path: 'dashboard/blogs/view-blog/:id',
    loadChildren: () => import('./view-blog/view-blog.module').then( m => m.ViewBlogPageModule)
  },
  {
    path: 'dashboard/health-tips',
    loadChildren: () => import('./health-tips/health-tips.module').then( m => m.HealthTipsPageModule)
  },
  {
    path: 'dashboard/health-tips/view-healthtip/:id',
    loadChildren: () => import('./view-healthtip/view-healthtip.module').then( m => m.ViewHealthtipPageModule)
  },
  {
    path: 'appointments/booked-appointment/:id',
    loadChildren: () => import('./booked-appointment/booked-appointment.module').then( m => m.BookedAppointmentPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'appointments/booked-appointment/:id/:type',
    loadChildren: () => import('./booked-appointment/booked-appointment.module').then( m => m.BookedAppointmentPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'appointments/meeting-details/:id',
    loadChildren: () => import('./meeting-details/meeting-details.module').then( m => m.MeetingDetailsPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'prescription/prescription-detail/:id',
    loadChildren: () => import('./prescription-detail/prescription-detail.module').then( m => m.PrescriptionDetailPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'prescription/investigation-specific-report/:id',
    loadChildren: () => import('./investigation-specific-report/investigation-specific-report.module').then( m => m.InvestigationSpecificReportPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'book-lab',
    loadChildren: () => import('./book-lab/book-lab.module').then( m => m.BookLabPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'lab-bookedlist',
    loadChildren: () => import('./lab-bookedlist/lab-bookedlist.module').then( m => m.LabBookedlistPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'lab-bookedlist/lab-bookedview/:id',
    loadChildren: () => import('./lab-bookedview/lab-bookedview.module').then( m => m.LabBookedviewPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'couponmodal',
    loadChildren: () => import('./couponmodal/couponmodal.module').then( m => m.CouponmodalPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin-support',
    loadChildren: () => import('./admin-support/admin-support.module').then( m => m.AdminSupportPageModule)
  },












  // {
  //   path: 'wallet',
  //   loadChildren: () => import('./wallet/wallet.module').then( m => m.WalletPageModule)
  // },



  // {
  //   path: 'patient-reports',
  //   loadChildren: () => import('./patient-reports/patient-reports.module').then( m => m.PatientReportsPageModule)
  // },



  // {
  //   path: 'health-tips',
  //   loadChildren: () => import('./health-tips/health-tips.module').then( m => m.HealthTipsPageModule)
  // },
  // {
  //   path: 'view-healthtip',
  //   loadChildren: () => import('./view-healthtip/view-healthtip.module').then( m => m.ViewHealthtipPageModule)
  // },

  // {
  //   path: 'upload-report',
  //   loadChildren: () => import('./upload-report/upload-report.module').then( m => m.UploadReportPageModule)
  // },
  // {
  //   path: 'view-blog',
  //   loadChildren: () => import('./view-blog/view-blog.module').then( m => m.ViewBlogPageModule)
  // },


  // {
  //   path: 'about-us',
  //   loadChildren: () => import('./about-us/about-us.module').then( m => m.AboutUsPageModule)
  // },





  // {
  //   path: 'manage-account',
  //   loadChildren: () => import('./manage-account/manage-account.module').then( m => m.ManageAccountPageModule)
  // },

  // {
  //   path: 'blogs',
  //   loadChildren: () => import('./blogs/blogs.module').then( m => m.BlogsPageModule)
  // },



  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  // },
  // {
  //   path: '',
  //   loadChildren: () => import('./tab-bar/tab-bar.module').then( m => m.TabBarPageModule)
  // },

  // {
  //   path: 'prescription',
  //   loadChildren: () => import('./prescription/prescription.module').then( m => m.PrescriptionPageModule)
  // },
  // {
  //   path: 'profile',
  //   loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  // },
  // {
  //   path: 'doctor-list',
  //   loadChildren: () => import('./doctor-list/doctor-list.module').then( m => m.DoctorListPageModule)
  // },

  // {
  //   path: 'video-call',
  //   loadChildren: () => import('./video-call/video-call.module').then( m => m.VideoCallPageModule),
  //   canActivate: [AuthGuardService],
  // },
  // {
  //   path: 'confirm-booking/:id',
  //   loadChildren: () => import('./confirm-booking/confirm-booking.module').then( m => m.ConfirmBookingPageModule),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'appointment',
  //   loadChildren: () => import('./appointment/appointment.module').then( m => m.AppointmentPageModule)
  // },
  // {
  // path: 'appointment/:id',
  // loadChildren: () =>import('./appointment/appointment.module').then(m => m.AppointmentPageModule),
  // },
  // {
  //   path: 'appointment/:id/:symptom',
  //   loadChildren: () =>import('./appointment/appointment.module').then(m => m.AppointmentPageModule)
  // },
  // {
  //   path: 'appointment-booking/:id',
  //   loadChildren: () => import('./appointment-booking/appointment-booking.module').then( m => m.AppointmentBookingPageModule)
  // },
  // {
  //   path: 'meeting-details/:id',
  //   loadChildren: () => import('./meeting-details/meeting-details.module').then( m => m.MeetingDetailsPageModule),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'upcoming-appointment',
  //   loadChildren: () => import('./upcoming-appointment/upcoming-appointment.module').then( m => m.UpcomingAppointmentPageModule),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'previous-appointment',
  //   loadChildren: () => import('./previous-appointment/previous-appointment.module').then( m => m.PreviousAppointmentPageModule),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'reports',
  //   loadChildren: () => import('./reports/reports.module').then( m => m.ReportsPageModule),
  //   canActivate: [AuthGuardService]
  // },

  // {
  //   path: 'edit-profile',
  //   loadChildren: () => import('./edit-profile/edit-profile.module').then( m => m.EditProfilePageModule),
  //   canActivate: [AuthGuardService]
  // },



  // {
  //   path: 'doctor-feedback/:id',
  //   loadChildren: () => import('./doctor-feedback/doctor-feedback.module').then( m => m.DoctorFeedbackPageModule),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'prescription-detail/:id',
  //   loadChildren: () => import('./prescription-detail/prescription-detail.module').then( m => m.PrescriptionDetailPageModule),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'create-subprofile',
  //   loadChildren: () => import('./create-subprofile/create-subprofile.module').then( m => m.CreateSubprofilePageModule),
  //   canActivate: [AuthGuardService]
  // },

  // {
  //   path: 'specialist',
  //   loadChildren: () => import('./specialist/specialist.module').then( m => m.SpecialistPageModule)
  // },
  // {
  //   path: 'symptoms',
  //   loadChildren: () => import('./symptoms/symptoms.module').then( m => m.SymptomsPageModule)
  // },
  // {
  //   path: 'booked-appointment/:id',
  //   loadChildren: () => import('./booked-appointment/booked-appointment.module').then( m => m.BookedAppointmentPageModule),
  //   canActivate: [AuthGuardService]
  // },
  // {
  //   path: 'view-doctor/:id',
  //   loadChildren: () => import('./view-doctor/view-doctor.module').then( m => m.ViewDoctorPageModule)
  // }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
