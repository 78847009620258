import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { LoadingService } from './services/loading.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { AppLauncher } from '@ionic-native/app-launcher/ngx';
import { Chooser } from '@awesome-cordova-plugins/chooser/ngx';
import { pageTransition } from './custom-animation';
import { SmsRetriever } from '@awesome-cordova-plugins/sms-retriever/ngx';



@NgModule({
  declarations: [	AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot({ navAnimation: pageTransition }), AppRoutingModule, GraphQLModule, HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    Camera,
    Network,
    LoadingService,
    LocationAccuracy,
    Geolocation,
    OneSignal,
    AppLauncher,
    Chooser,
    SmsRetriever,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
