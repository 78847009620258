import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo,gql, QueryRef } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs';
import { tap, delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  public wallet_amount:BehaviorSubject<String> = new BehaviorSubject<String>('');
  amount:any;

  get_User:QueryRef<any>;
  get_users:QueryRef<any>;
  get_coins:QueryRef<any>;
  get_coupons:QueryRef<any>;
  totalwalletamount:QueryRef<any>;
  homewalletamount:QueryRef<any>;
  WalletTranasaction:QueryRef<any>;

constructor(private apollo:Apollo  ) {
  this.wallet_amount=new BehaviorSubject(this.amount);

}

// setwalletamount(data){
//   this.wallet_amount.next(data)
// }

regis_user(data){
  const resgister_POST = gql`
  mutation patient(
    $email: String!,
    $password: String!,
    $name: String!,
    $user: String!,
    $mobileno: String!,
    $gender: String,
    $dob: String,
    $location: String,
    ) {
    register(email: $email, password: $password, name: $name, user: $user, mobileno: $mobileno ,gender :$gender,dob :$dob ,location :$location) {
        result {
          accessToken
          message
      }
    }
  }`;

    return this.apollo.mutate({
      mutation: resgister_POST,
      variables: data
    }).pipe(
      tap((data) => {
        data;
      })
    );
}

registerPatient(patient_data){

  const patient_POST = gql`
  mutation patient($dob: date!, $email: String!, $first_name: String!,$gender : String!,
    ,$mobile: String!) {
    insert_patient_registration(objects:{ dob: $dob, email: $email, first_name: $first_name,gender : $gender ,
        mobile: $mobile}) {
        returning {
        id
        last_name
        first_name
      }
    }
  }`;

    return this.apollo.mutate({
      mutation: patient_POST,
      variables: patient_data,
    }).pipe(
      tap((data) => {
        data;
      })
    );
  }


  registerPatients(patient_data,token){

    const patient_POST = gql`
    mutation patient($dob: date!, $email: String!, $first_name: String!,$gender : String!,
      $mobile: String!) {
      insert_patient_registration(objects:{ dob: $dob, email: $email, first_name: $first_name,gender : $gender ,
        mobile: $mobile}) {
          returning {
          id
          last_name
          first_name
          email
          mobile
        }
      }
    }`;

      return this.apollo.mutate({
        mutation: patient_POST,
        variables: patient_data,
        context: {
          headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
        },
      }).pipe(
        tap((data) => {
          data;
        })
      );
    }





    upload_profile_pic(data){
      const resgister_POST = gql`
      mutation profile_pic(
        $id: Int!,
        $image: String!,
        $relation: String!,
        $gender: String!
        ) {
          profile_upload(id: $id, image: $image ,relation: $relation,gender: $gender) {
            result {
              message
          }
        }
      }`;
        return this.apollo.mutate({
          mutation: resgister_POST,
          variables: data
        }).pipe(
          tap((data) => {
            data;
          })
        );

    }

    getUser(id){

      this.get_User= this.apollo
  .watchQuery({
    query: gql`{
      patient_registration (where:{id : {_eq: ${id} }}) {
        email
        dob
        first_name
        gender
        id
        last_name
        referal_code
        mobile
        address_line
        addressline1
        city
        country
        languages
        state
        other_medical
        type_patient
        res_users
        parent_patient
        res_user {
          partner_id
          res_partner {
            mail_channel_partners {
              channel_id
            }
          }
        }
      }
    }`,
    fetchPolicy:'network-only',
  });
  return this.get_User.valueChanges.pipe(
    tap((data) => {
        data;
    })
    );
  }

  refetch_getUser(){
    this.get_User.refetch();
  }

  getUsers(){
    this.get_users= this.apollo
.watchQuery({
  query: gql`{
    patient_registration(order_by: {id: asc}) {
      email
      first_name
      gender
      id
      last_name
      referal_code
      mobile
      address_line
      addressline1
      city
      country
      languages
      state
      other_medical
      res_users
      parent_patient 
    }
  }`,
  fetchPolicy:'network-only',
});
return this.get_users.valueChanges.pipe(
  tap((data) => {
      data;
  })
  );
}

refetch_getUsers(){
  this.get_users.refetch();
}

update_res_user(data){
  const update_res_user_POST = gql`
    mutation patient(
  $ids: Int!,
  $email: String!,
  $name: String!,
  $user: String!,
  $mobileno: String!,
  $gender: String,
  $dob: String,
  $location: String,
  ) {
    updateresuser(ids: $ids,email: $email, name: $name, user: $user, mobileno: $mobileno ,gender :$gender,dob :$dob ,location :$location) {
      result {
        message
    }
  }
}`;

  return this.apollo.mutate({
    mutation: update_res_user_POST,
    variables: data
  }).pipe(
    tap((data) => {
      data;
    })
  );
}

  updatePatient(id,patient_data){
    const patient_POST = gql`
    mutation update_patient( $email: String!,
       $first_name: String!,
       $mobile: String!,
       $gender: String!,
       $dob: date!,
       $address_line: String!,
       $addressline1: String,
       $other_medical: String,
       $city:  Int,
       $country:  Int,
       $state:  Int,
       $languages:  Int
       ) {
        update_patient_registration(where: {id: {_eq: ${id}}}, _set: {
          email: $email,
          first_name: $first_name,
          mobile: $mobile,
          gender: $gender,
          address_line: $address_line,
          addressline1: $addressline1,
          other_medical: $other_medical,
          city: $city,
          country: $country,
          state: $state,
          languages:$languages ,
          dob: $dob }) {
          returning {
            id
            first_name
            email
            mobile
        }
      }
    }`;

      return this.apollo.mutate({
        mutation: patient_POST,
        variables: patient_data,
      }).pipe(
        tap((data) => {
          data;
        })
      );
  }


  create_subprofile(patient_data){

    const patient_POST = gql`
    mutation patient($dob: date!, $email: String!, $first_name: String!,$gender : String!,$type_patient :String!
      ,$mobile: String! , $parent_patient :Int!, $other_medical:String!) {
      insert_patient_registration(objects:{ dob: $dob, email: $email, first_name: $first_name,gender : $gender ,
        type_patient: $type_patient, mobile: $mobile , parent_patient :$parent_patient ,other_medical:$other_medical}) {
          returning {
          id
          first_name
        }
      }
    }`;

      return this.apollo.mutate({
        mutation: patient_POST,
        variables: patient_data,
      }).pipe(
        tap((data) => {
          data;
        })
      );
    }

    uploadReport(data){

      const resgister_POST = gql`
      mutation reports_upload(
        $id: Int!,
        $attachment: String!,
        $types: String!,
        ) {
          upload_patientreport(id: $id, attachment: $attachment ,types: $types) {
            result {
              message
          }
        }
      }`;
        return this.apollo.mutate({
          mutation: resgister_POST,
          variables: data
        }).pipe(
          tap((data) => {
            data;
          })
        );

    }


    getCoins(){
      this.get_coins= this.apollo.watchQuery({
        query: gql`{
          account_wallet {
            id
            active
            balance
            coupon_id
            name
            wallet_type_id
          }
        }`,
        fetchPolicy:'network-only',
      });
      return this.get_coins.valueChanges.pipe(
        tap((data) => {
            data;
        })
        );
      }

  refetch_getCoins(){
    this.get_coins.refetch();
  }

  getCoupons(){
    this.get_coupons= this.apollo.watchQuery({
      query: gql` {
        coupon_program (where: {program_type: {_eq: "promotion_program"}}){
          active
          id
          maximum_use_number
          name
          promo_applicability
          promo_code
          promo_code_usage
          validity_duration
          coupon_rule {
            id
            rule_date_from
            rule_date_to
            rule_min_quantity
            rule_minimum_amount
            rule_minimum_amount_tax_inclusion
          }
          coupon_reward {
            discount_apply_on
            discount_fixed_amount
            discount_max_amount
            discount_percentage
            discount_type
            reward_description
            reward_type
            id
          }
        }
      }`,
      fetchPolicy:'network-only',
    });
    return this.get_coupons.valueChanges.pipe(
      tap((data) => {
          data;
      })
      );
    }

getValidCoupons(){
  const coupons_POST = gql`
    mutation MyMutation {
      valid_promo {
        result {
          message
          active_count
        }
      }
    }`;
    return this.apollo.mutate({
      mutation: coupons_POST,
    }).pipe(
      tap((data) => {
        data;
      })
    );
}


refetch_getCoupons(){
  this.get_coupons.refetch();
}


homegetwalletamount(){
  this.homewalletamount= this.apollo.watchQuery({
    query: gql`{
      account_wallet {
        balance
        id
        name
      }
    }`,
    fetchPolicy:'network-only',
  });
  return this.homewalletamount.valueChanges.pipe(
    tap((data) => {
        data;
    })
    );
  }

getTotalwalletamount(){
  this.totalwalletamount= this.apollo.watchQuery({
    query: gql`{
      account_wallet {
        balance
        id
        name
      }
    }`,
    fetchPolicy:'network-only',
  });
  return this.totalwalletamount.valueChanges.pipe(
    tap((data) => {
        data;
    })
    );
  }

  WalletTransactionHistory(){
    this.WalletTranasaction= this.apollo.watchQuery({
      query: gql`{
        account_wallet {
          name
          id
          account_move_lines (order_by: {id: asc}) {
            id
            credit
            debit
            move_id
            name
            create_date
          }
        }
      }`,
      fetchPolicy:'network-only',
    });
    return this.WalletTranasaction.valueChanges.pipe(
      tap((data) => {
          data;
      })
      );
  }

  refetch_getTotalwalletamount(){
    this.totalwalletamount.refetch();
  }


  getCoupon_bycode(coupon_code){
      return this.apollo.watchQuery({
      query: gql`{
        coupon_program(where: {promo_code: {_eq: "${coupon_code}"}}) {
          active
          id
          maximum_use_number
          name
          promo_applicability
          promo_code
          promo_code_usage
          validity_duration
          coupon_reward {
            discount_apply_on
            discount_fixed_amount
            discount_max_amount
            discount_percentage
            discount_type
            reward_description
            reward_type
            id
          }
        }
      }`,
      fetchPolicy:'network-only',
    }).valueChanges.pipe(
      tap((data) => {
          data;
      })
      );
    }

  couponValidation(validation_details){
    const coupon_detail = gql`
    mutation coupon_validation(
      $coupon_id: Int!,
      $patient_id:  Int!,
      $fees:String!,
      $types:String!,
      ) {
        coupon_validation(coupon_id: $coupon_id, paitents_details: $patient_id,fees:$fees,types:$types) {
          result {
            coupon_id
            message
            status
            name
            new_fees
            discount_amount 
        }
      }
    }`;
      return this.apollo.mutate({
        mutation: coupon_detail,
        variables: validation_details
      }).pipe(
        tap((data) => {
          data;
        })
      );
  }
}



