import { AuthenticationService } from './services/authentication.service';
import { takeUntil } from 'rxjs/operators';
import { PatientService } from './services/patient.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@capacitor/storage';
import { Subject, Subscription } from 'rxjs';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { ToastService } from './services/toast.service';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { AlertController } from '@ionic/angular';
import { LoadingService } from './services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public appPages = [
    { title: 'Home', url: '/dashboard', src: 'assets/home_1.svg' ,open: false},
    { title: 'Appointments', url: '/appointments', src: 'assets/appoint_meet.svg' ,open: false},
    // { title: 'Reports', url: '/prescription', src: 'assets/reports-1.svg', open: false },
    // { title: 'Notifications', url: '/notifications', src: 'assets/notify-1.svg' ,open: false},
    { title: 'Wallet', url: '/wallet', src: 'assets/wallet.svg' ,open: false},
    { title: 'Help & Support', url: '/admin-support', src: 'assets/Supports.svg' ,open: false},
    // { title: 'Help & Support', url: '/support', src: 'assets/Supports.svg' ,open: false,subPages:[{title: 'Mail', url:"mailto:support@healthuno.com", src: 'assets/contacts.svg' ,open: false},{title: 'Contact', url:"tel:8001156789", src: 'assets/call.svg' ,open: false},{title: 'Chat', url: '/support', src: 'assets/mail.svg' ,open: false}]},
    { title: 'FAQ', url: '/faq', src: 'assets/faqss.svg' ,open: false},
    // { title: 'Terms and Conditions', url: '/termsandcondition', src: 'assets/terms-1.svg', open: false },
    { title: 'About us', url: '/about-us', src: 'assets/Abouts.svg', open: false },
  ];
  public labels = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  user: any;
  id: any;
  rand:any;
  profile_pic:any;
  connectSubscription:Subscription;
  disconnectSubscription:Subscription;
  coins:any;

  constructor(private _router: Router,
    private _patientService:PatientService,
    private network: Network,
    private loadingService:LoadingService,
    private toastService: ToastService,
    private oneSignal: OneSignal,
    private alertCtrl: AlertController,
    private authService: AuthenticationService) {



    this.initPushNotification();
      // print oneSignal id
      this.oneSignal.getIds().then((id) => {
        console.log(id.userId);
      });

      this.network_diconnection();

      Storage.get({ key: 'intro' }).then(user => {
        if(user.value == null){
          Storage.set({ key: 'intro', value: 'true' });
          this._router.navigateByUrl('/home');
          }
      });

      this.authService.userdata.subscribe(data => {
        this.user = data;
        // console.log('avc',this.user)
        if(!this.user){
          Storage.get({ key: 'user' }).then(user => {
            this.user = JSON.parse(user.value);
            if (this.user){
              this.getUser(this.user?.id);
              this.get_coins();

            }

          });
        }else{
          this.getUser(this.user?.id);
          this.get_coins();

        }
      });

      this.rand = Math.floor(Math.random() * 100) + 1;

      this.authService.profilepic.subscribe((data:any) => {
          this.profile_pic = data?.data;
      });


  }

  ngOnInit() {
    // console.log(this.getUser(this.user?.id));
    // console.log('this.user',this.user)
    // if (this.user){
      // this.get_coins();
    // }
    // this.get_coins();
    setTimeout(() => {
      console.log('aas',this.user)
    }, 3000);
  }

    get_coins(){
     this._patientService.homegetwalletamount().subscribe((result: any) => {
      this.coins=result?.data?.account_wallet?.[0]?.balance;
    });
    // this._patientService.getTotalwalletamount().subscribe((result: any) => {
    //   this.coins=result?.data?.account_wallet?.[0]?.balance;
    // });
  }


   getUser(id){
    this._patientService.getUser(id)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((result: any) => {
      this.user=result.data?.patient_registration?.[0];
    });
  }

  logout() {
    // replace url login
    this.authService.logout();
    window.location.href = "/login";
    // this._router.navigate(['/login'], { replaceUrl: true });
  }




network_diconnection(){
    // watch network for a disconnection
  this.disconnectSubscription = this.network.onDisconnect().subscribe(() => {
    // animation network disconnection in alert

    let message="<div><img src='assets/gif/no-internet.gif'/></div><p>Please check your internet connection</p>";
    this.createAlert('Network Disconnected', message ).then(alert => {
      alert.present();
    });

    this.toastService.presentToast('No internet connection','dark','bottom');
    console.log('network was disconnected :-(');
    this.network_connection();
  });

}

network_connection(){
  this.unsubscribe_disconect();
  // watch network for a connection
this.connectSubscription = this.network.onConnect().subscribe(() => {
  this.toastService.presentToast('Network connected!','dark','bottom');
  this.network_diconnection();
  console.log('network connected!');
  this.alertCtrl.dismiss();
  this.unsubscribe_connect();
  // We just got a connection but we need to wait briefly
   // before we determine the connection type. Might need to wait.
  // prior to doing any api requests as well.
  setTimeout(() => {
    if (this.network.type === 'wifi') {
      this.network_diconnection();
      this.toastService.presentToast('Network connected!','dark','bottom');
      console.log('we got a wifi connection, woohoo!');
      this.alertCtrl.dismiss();
      this.unsubscribe_connect();
    }
  }, 3000);
});

}

unsubscribe_disconect(){
// stop disconnect watch
this.disconnectSubscription.unsubscribe();

}
unsubscribe_connect(){
// stop connect watch
this.connectSubscription.unsubscribe();
}



// push notification
  async initPushNotification() {
    this.oneSignal.startInit('17d10de5-ed8d-4634-936f-065de3b4a572', '1049910101808');
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);
    this.oneSignal.handleNotificationReceived().subscribe(() => {
      // do something when notification is received
    });
    this.oneSignal.handleNotificationOpened().subscribe((data) => {
      // do something when a notification is opened
      // console.log(data.notification.payload.additionalData.appoint_id)
      if(data.notification.payload.additionalData){
        this._router.navigate(['/doctor-chat/' + data.notification.payload.additionalData.appoint_id]);
      }
    });
    this.oneSignal.endInit();
  }


  async createAlert(header, message): Promise<HTMLIonAlertElement> {
    const alert = await this.alertCtrl.create({
    header,
    cssClass: 'nointernet',
    backdropDismiss: false,
    message
    });
    return alert;
    }

    ngOnDestroy() {
      // this.subscription.unsubscribe();
    }

}



