import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable()
export class LoadingService {

  constructor(private loadingCtrl: LoadingController) {}

  async showLoading(duration) {
    const loading = await this.loadingCtrl.create({
      message: 'Loading...',
      duration: duration,
      spinner: 'circles',
    });

    loading.present();
  }


  async hideLoading() {
    this.loadingCtrl.dismiss();
  }
  async Loading() {
    const loading = await this.loadingCtrl.create({
      message: 'Loading...',
      spinner: 'circles',
    });

    loading.present();
  }


}
