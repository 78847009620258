import { gql, Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Storage } from '@capacitor/storage';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public localdata :BehaviorSubject<String> = new BehaviorSubject<String>('');
  public userdata :BehaviorSubject<String> = new BehaviorSubject<String>('');
  public profilepic:BehaviorSubject<String> = new BehaviorSubject<String>('');
  platform_type:any
  token:any;
  user_details:any;
  picture:any;



  constructor(private apollo:Apollo,
    private _platform:Platform) {
    this.localdata=new BehaviorSubject(this.token);
    this.userdata=new BehaviorSubject(this.user_details);
    this.profilepic=new BehaviorSubject(this.picture);
    if(this._platform.is('android')){
      this.platform_type="android"
    }
   }

   setlocalstorage(data:any){
    console.log(data)
    this.localdata.next(data)
  }

  setuserdata(data){
    this.userdata.next(data)
  }

  setprofilepic(data){
    console.log('chnage img',data)
    this.profilepic.next(data)
  }




  email_login(data) {
    const email_POST = gql`
    mutation patient($email: String!,$password: String!) {
      login(email: $email, password: $password) {
          result {
            accessToken
            message
            status
            type_users
            status_message
        }
      }
    }`;

      return this.apollo.mutate({
        mutation: email_POST,
        variables: data
      }).pipe(
        tap((data) => {
          data;
        })
      );
  }

  sendotp(mobile){
    const patient_POST = gql`
    mutation login($phone_number: String!,$sms_code: String!) {
      sendOtp(phone_number: $phone_number,sms_code:$sms_code) {
        result {
          message
          status
          sms_code
        }
      }
    }`;

      return this.apollo.mutate({
        mutation: patient_POST,
        variables: mobile
      }).pipe(
        tap((data) => {
          data;
        })
      );
  }


  otplogin(otpdata){
    const patient_POST = gql`
    mutation login($phone_number: String!,$otp: String!) {
      Otplogin(phone_number: $phone_number,otp: $otp) {
        result {
          accessToken
          message
          status
          type_users
        }
      }
    }`;


      return this.apollo.mutate({
        mutation: patient_POST,
        variables: otpdata
      }).pipe(
        tap((data) => {
          data;
        })
      );
  }

  updatePassword(password_data){
    const patient_POST = gql`
    mutation updatePassword($password: String!,$old_password: String!) {
      change_pass(password: $password, old_password: $old_password) {
        result {
          message
        }
      }
    }`;
      return this.apollo.mutate({
        mutation: patient_POST,
        variables: password_data
      }).pipe(
        tap((data) => {
          data;
        })
      );
  }

  updateOneSignalId(data){
    const patient_POST = gql`
    mutation updateOneSignalId($key: String!,$signal_type:String!) {
      insert_one_signal_master(objects: {key: $key,signal_type:$signal_type}) {
        affected_rows
      }
    }`;
      return this.apollo.mutate({
        mutation: patient_POST,
        variables:{'key':data,'signal_type':this.platform_type}
      }).pipe(
        tap((data) => {
          data;
        })
      );
  }

  logout(){
      Storage.remove({ key: 'user' });
      localStorage.removeItem('accessToken');
      Storage.remove({ key: 'accessToken' });
      this.localdata.next('');
      this.userdata.next('');
  }

}
