
import { AuthenticationService } from './../services/authentication.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit} from '@angular/core';
import { Storage } from '@capacitor/storage';
import { MenuController, ToastController } from '@ionic/angular';
import { PatientService } from '../services/patient.service';
import { NavController } from '@ionic/angular';
import { LoadingService } from '../services/loading.service';
import { SmsRetriever } from '@awesome-cordova-plugins/sms-retriever/ngx';
import { Platform } from '@ionic/angular';


@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  mobile :any="";
  loginForm: FormGroup;
  isSubmitted = false;
  _unsubscribeAll: Subject<any> = new Subject<any>();
  mobile_no:any;
  button_status:any;
  sms_code:any;
  convert_sms_code:any;

  constructor(

    public menu: MenuController,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    public formBuilder: FormBuilder,
    public toastController: ToastController,
    private smsRetriever: SmsRetriever,
    private _authService: AuthenticationService,
    public navbar:NavController,
    private loadingService:LoadingService,




  ) {
    this.smsRetriever.getAppHash()
    .then((res: any) => {
      this.sms_code=res
      console.log('login')
    })
    .catch((error: any) => console.error(error));

    this.smsRetriever.startWatching()
      .then((res: any) =>{
        console.log('login listen',res)
      })
      .catch((error: any) => console.error('l',error));
    this.menu.enable(false);
    this._authService.logout();
   }




   async presentToast(message,color,position) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: position,
      color: color
    });
    toast.present();
  }


  ngOnInit() {
    
    this.loginForm = this.formBuilder.group({
      phone_number: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[6-9][0-9]{9}$"),Validators.minLength(10),Validators.maxLength(10)]],

    });
  }

  ionViewWillEnter(){
    this.mobile='';
  }

  send_opt(){
    this._router.navigate(['../otp-verification'],{relativeTo: this.activatedRoute});
  }

  submitForm() {
    let get_code=this.sms_code.replace('+','%2B')
    console.log(get_code)
    this.convert_sms_code=get_code
    this.button_status="disabled";

    this.isSubmitted = true;
    if (!this.loginForm.valid) {
      this.presentToast('Please enter valid mobile number','dark','bottom');
      this.button_status="enabled";
      return false;
      } 
      else {
        this.loadingService.showLoading(1500)
        this.mobile_no={
          "phone_number":(this.loginForm.value.phone_number).toString(),
          "sms_code":this.convert_sms_code
        };
        
        console.log("sms_code",this.mobile_no)
        this._authService.sendotp(this.mobile_no).pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result: any) => {
        console.log(result.data.sendOtp.result.status);
        if (result.data.sendOtp.result.status == 'success' || result.data.sendOtp.result.status == 'newuser') {
          // this.navbar.navigateRoot(['../otp-verification',this.mobile_no.phone_number],{relativeTo: this.activatedRoute});
          // this.loadingService.hideLoading()
          this._router.navigate(['../otp-verification',this.mobile_no.phone_number],{replaceUrl: true});
          this.presentToast(result.data.sendOtp.result.message,'success','top');
        }
        else{
          // this.loadingService.hideLoading()
          this.button_status="enabled";
          this.presentToast(result.data.sendOtp.result.message,'danger','top');
          // this._router.navigate(['/registration',this.mobile_no],{relativeTo: this.activatedRoute});
        }

        });
      }
  }

emailLogin(){
  this._router.navigate(['/email-login'],{replaceUrl: true});

}


}
